.ClickableRow {
	cursor: pointer;
}
.StatusBadge {
	width: 100px;
	justify-content: center;
	align-items: center;
	border: 3px solid var(--control-mid);
	border-radius: 15px;
	background-color: var(--control-light);
	color: var(--control-mid);
	padding: 5px;
	margin: 3px;
}

.DefaultBadge {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	/*border: 3px solid var(--control-mid);
	border-radius: 5px;*/
	background-color: transparent;
	color: var(--control-mid);
	padding: 5px;
	margin: 3px;
	font-size: large;
}

.TelemetryBadge {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	/*border: 3px solid var(--invoice-dark);*/
	border-radius: 30px;
	background-color: var(--invoice);
	color: var(--invoice-light);
	padding: 5px;
	margin: 3px;
	font-size: medium;
}

.RemoteBadge {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	/*border: 3px solid var(--purchases-dark);*/
	border-radius: 30px;
	background-color: var(--purchases);
	color: var(--purchases-light);
	padding: 5px;
	margin: 3px;
	font-size: medium;
}

.InvisibleBadge {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	/*border: 3px solid var(--message-dark);*/
	border-radius: 30px;
	background-color: var(--message);
	color: var(--message-light);
	padding: 5px;
	margin: 3px;
	font-size: medium;
}

.RemoteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 30px;
	border: 1px solid var(--balance);
	border-radius: 3px;
	background-color: var(--balance-mid);
	color: var(--balance);

	margin: 3px;
	font-size: medium;
}
.RemoteButton:hover {
	border: 3px solid var(--balance);
	background-color: var(--balance);
	color: var(--balance-mid);
}
.RemoteButton:active {
	border: 3px solid var(--balance);
	background-color: var(--balance);
	color: var(--balance-mid);
}

.DeleteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 11rem;
	border-radius: 3px;
	border: 0px;
	background-color: var(--inv-tuch-error);
	color: var(--invoice-light);
	margin: 20px;
}
.DeleteButton:hover {
	background-color: var(--label-red-dark);
	color: var(--invoice-mid);
}
.DeleteButton:active {
	background-color: var(--label-red-dark) !important;
	color: var(--invoice-mid) !important;
}
