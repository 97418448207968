.ActivateButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 9rem;
	color: var(--message);
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.ActivateButton:hover {
	color: var(--message-light);
	background-color: var(--message);
	border-color: transparent;
}

.ActivateButton:focus {
	color: var(--message-light);
	background-color: var(--message);
	border-color: transparent;
}

.ActivateButton:focus {
	color: var(--message-light) !important;
	background-color: var(--message) !important;
	border-color: transparent !important;
}

.MachineTooltip {
	color: var(--message);
	background-color: transparent;
	border-color: transparent;
	text-align: left;
	font-size: 18px;
}

.MachineTooltip:hover {
	color: var(--message-dark);
	background-color: transparent;
	border-color: transparent;
}

.DownloadButton {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2.3rem;
	border-radius: 3px;
	color: var(--balance);
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.DownloadButton:hover {
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: transparent;
}

.DownloadButton:focus {
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: transparent;
}

.DownloadButton:focus {
	color: var(--balance-light) !important;
	background-color: var(--balance) !important;
	border-color: transparent !important;
}

.DownloadButtonLoading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2.3rem;
	border-radius: 3px;
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: transparent;
	text-align: left;
}
.DownloadButtonLoading:hover {
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: transparent;
}

.DownloadButtonLoading:focus {
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: transparent;
}

.DownloadButtonLoading:active {
	color: var(--balance-light) !important;
	background-color: var(--balance) !important;
	border-color: transparent !important;
}
.DeleteButton {
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}
