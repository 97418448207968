.ToolbarDiv {
	text-align: right;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-right: 2rem;
}

.NotifiactionListDiv {
	overflow-y: scroll;
	height: 500px;
	padding-top: 15px;
}

.MachineListDiv {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 470px;
}

.FilterClearButton {
	width: 2.5rem;
	padding-bottom: 9px;
}

.FilterSelectionButton {
	width: 100%;
}

.PaddingRight {
	padding-right: 2px;
}

.PaddingLeft {
	padding-left: 2px;
}
