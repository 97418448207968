.IncomeSwitchButton {
	background-color: var(--control-mid);
	color: var(--control-dark);
	border-color: white;
	width: 9rem;
	border-radius: 3px;
}

.IncomeSwitchButton:disabled {
	border-color: white;
	background-color: var(--inv-tuch-error);
	color: var(--control-light);
	width: 9rem;
	border-radius: 3px;
}

.HeaderCard {
	flex: 0 0 20%;
	min-width: 392px;
	padding-left: 10px;
	padding-right: 10px;
}

.RootCard {
	word-wrap: break-word;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid #c8ced3;
	border-radius: 0.25rem;
	margin-bottom: 10px;
}

.RootCardTraffic {
	width: 100%;
	margin: 10px;
}

.RootCardMachines {
	width: 750px;
	margin: 10px;
}

.Row {
	margin: 0px;
}

.NotificationCard {
	flex: 0 0 30%;
	min-width: 680px;
	margin-left: 0px;
	margin: 10px;
}

.MachineMapCard {
	margin: 10px;

	min-width: 300px;
	width: 100%;
}
