.Password {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

.Error {
	color: red;
}

.RegisterButton {
	float: right;
}
