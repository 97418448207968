.MachineTitle {
	font-size: 1.53125rem;
	color: white;
	display: inline-block;
}

.MachineInterface {
	position: relative;
}

.Overlay {
	width: 101%;
	height: 100%;
	background-color: #4a4444d9;
	display: block;
	position: absolute;
	z-index: 100;
}

.CardTitle {
	min-height: 50px;
}
