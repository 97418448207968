.DateParagraph {
	margin-bottom: 0px;
}

.IconDiv {
	display: inline-block;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	left: 1.5rem;
	text-align: center;
}

.TextDiv {
	display: inline-table;
	margin-left: 2rem;
	cursor: pointer !important;
}

.NotificationListItem {
	border: 0px;
	border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
