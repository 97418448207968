.IconAlign {
	vertical-align: top;
	margin-top: 5px;
}
.TextDiv {
	display: inline-block;
}

.TextDivAmount {
	display: inline-block;
	float: right;
}
.Header {
	text-align: center;
}

.BodyStyle {
	padding: 0.75rem;
}

.ToolbarDiv {
	text-align: right;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
}

.DatePicker,
.DatePicker > div.react-datepicker-wrapper,
.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	input {
	width: 100%;
}
