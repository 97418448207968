.DeleteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.EditButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}
.EditButton:hover {
	color: white;
	background-color: #6ebc3e;
}

.DeactivateButton {
	color: #800000;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.DeactivateButton:hover {
	color: white;
	background-color: #800000;
	border-color: transparent;
}

.ActivateButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #4682b4;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.ActivateButton:hover {
	color: white;
	background-color: #4682b4;
	border-color: transparent;
}

.DescriptionButton {
	color: #ffa500;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.DescriptionButton:hover {
	color: white;
	background-color: #ffa500;
	border-color: transparent;
}

.TableClass {
	background-color: var(--message);
}
.HeaderClass {
	background-color: var(--message-mid);
}
