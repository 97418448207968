:root {
	--inv-tuch-error: #db021d;
	--balance: #6ebc3e;
	--balance-light: #f0f8ec;
	--balance-dark: #528d2e;
	--balance-mid: #e2f2d8;
	--failedtransaction-mid: #f86c6b;
	--failedtransaction-light: #fee2e1;
	--invoice: #800000;
	--invoice-light: #f9f2f2;
	--invoice-dark: #660000;
	--invoice-mid: #ecd9d9;
	--message: #4682b4;
	--message-light: #f6f9fb;
	--message-dark: #3c6e99;
	--message-mid: #e3ecf4;
	--purchases: #ffa500;
	--purchases-light: #fff6e6;
	--purchases-dark: #b27300;
	--purchases-mid: #ffedcc;
	--control-dark: #444d58;
	--control-mid: #c7cacd;
	--control-light: #ecedee;
	--label-red: #f86c6b;
	--label-red-dark: #954140;
	--label-red-light: #fee2e1;
}
