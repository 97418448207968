/* Custom properties */
:root {
    --tooltip-text-color: black;
    --tooltip-background-color: #fffffa;
    --tooltip-margin: 20px;
    --tooltip-arrow-size: 6px;
  }
  
  /* Wrapping */
  .Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }
  
  /* Absolute positioning */
  .Tooltip-Tip {
    position: absolute;
    border-radius: 15px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #cccccc;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    line-height: 1; 
    z-index: 100;
    box-shadow: 3px 2px 10px 1px #999999;
    font-size: smaller;
  }
  .Label{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .Content{
    border-radius: 15px;
    background-color: #fffffa;
    overflow: hidden;
    height: 75%;
    width: 100%;
    padding: 8px;
    padding-left: 10px;
   
  }

  .P{
    position: relative;
  }

  .Title{
    border-radius: 15px 15px 1px 1px;
    background-color: #F8F8FF;
    overflow: hidden;
    height: 25%;
    width: 100%;   
    text-align:left; 
    padding: 8px; 
    border-bottom: 1px double #DCDCDC;
    font-weight: bold;
    padding-left: 10px
  }
  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.top {
    top: calc(var(--tooltip-margin) * -6);
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -6);
  }
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
  }
  