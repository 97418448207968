.DatePicker {
	width: 100%;
}

.row {
	margin-top: 1rem;
}

.col {
	text-align: right;
	margin: auto;
}

.leftCol {
	margin-right: 0;
	padding-right: 0px;
}

.AddButton {
	width: 50%;
	min-width: 67px;
	float: right;
	margin: auto;
}

.DownloadButton {
	width: 100%;
	min-width: 67px;
	float: right;
	margin: auto;
}

.MachineList {
	overflow-x: auto;
	display: inline-flex;
}

.MachinesDiv {
	overflow-y: auto;
	width: 100%;
}
.MachineItem {
	height: 37px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
	border-radius: 4px;
	margin-right: 5px !important;
	background-color: #4dbd74;
	border: 0px;
	color: white;
	font-size: 14px;
	font-weight: 400;
	cursor: pointer !important;
	padding-top: 9px;
}

.CursorPointer {
	cursor: pointer;
}
