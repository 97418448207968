.HeaderRow {
	background-color: var(--control-dark);
	color: var(--control-light);
	padding: 10px;
	font-size: large;
}
.HeaderCol {
	background-color: var(--control-dark);
	color: var(--control-light);
	padding: 10px;
	font-size: large;
}

.HeaderRowSlave {
	background-color: var(--control-dark);
	color: var(--control-light);
	padding: 10px;
	font-size: large;
}
.SlaveCol {
	display: flex;
	justify-content: center;
	font-size: x-large;
	font-weight: bold;
}

.LabelCol {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--control-light);
	color: var(--control-dark);
	border-radius: 5px;
	padding-top: 5px;
	margin-bottom: 15px;
	margin-left: 15px;
	font-weight: bold;
}

.LocationCard {
	height: 90%;
	margin: 10px;
	background-color: var(--control-light);
	padding: 5px;
}

.CommandRow {
	align-items: center;
	justify-content: space-between;
	margin-top: 5px;
	padding-top: 20px;
	border-top: 1px solid var(--control-mid);
}

.BtnCol {
	display: flex;
	flex-direction: 'collumn';
	align-items: center;
	justify-content: flex-end;
}
