.Col {
	background-color: ghostwhite;
	/* height: 80px; */
}

.ColEdit {
	background-color: white;
	/* height: 80px; */
}
.IncomeChart {
	background-color: var(--invoice-light);
}
