.Badge {
	background-color: #4682b4;
	font-size: x-small;
	border-radius: 30px;
	margin: 0.4rem;
	width: 1rem;
	height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #f8f8ff;
}

.Badge:hover {
	background-color: #f8f8ff;
	color: #4682b4;
}
