.SummaryRow {
	margin-top: 5px;
	align-items: flex-end;
	justify-content: space-between;
	font-size: medium;
	font-weight: bold;
	margin-left: 30px;
	padding-bottom: 5px;
	border-bottom: 1px solid var(--control-mid);
}

.TableToolbar {
	margin-top: 15px;
	align-items: flex-end;
	justify-content: space-between;
	font-size: medium;
	font-weight: bold;
	margin-left: 30px;
	margin-right: 30px;
	padding-bottom: 15px;
	border-bottom: 1px solid var(--control-mid);
}

.TableHeaderClass {
	padding-top: 10px;
	padding-bottom: 10px;
}

.TableHeader {
	margin-top: 20px;
	align-items: flex-end;
	justify-content: space-between;
}

.ApproveButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	color: var(--balance-mid);
	background-color: var(--balance);
	border-radius: 3px;
	border-color: transparent;
}

.ApproveButton:hover {
	color: var(--balance-light);
	background-color: var(--balance-dark);
}

.ApproveButton:active {
	color: var(--balance-light) !important;
	background-color: var(--balance) !important;
}

.DeclainButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	color: var(--invoice-mid);
	background-color: var(--invoice);
	border-radius: 3px;
	border-color: transparent;
}

.DeclainButton:hover {
	color: var(--invoice-light);
	background-color: var(--invoice-dark);
}

.DeclainButton:active {
	color: var(--invoice-light) !important;
	background-color: var(--invoice) !important;
}

.ApprovaledButton {
	color: var(--balance);
	background-color: transparent;
	border-radius: 10px;
	border-color: transparent;
	font-size: large;
	padding-top: 10px;
}

.ExportExcelButton {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--message-light);
	background-color: var(--message);
	font-size: 11.5px;
	height: 30px;
}
.ExportExcelButton:hover {
	color: var(--message-mid);
	background-color: var(--message-dark);
}
.ExportExcelButton:active {
	color: var(--message-mid) !important;
	background-color: var(--message-dark) !important;
}

.Date {
	border-radius: 5px;
	border-color: var(--control-mid);
	margin-top: 5px;
	margin-bottom: 5px;
}
.InvoiceRow {
	background-color: var(--invoice-light);
}

.InvoiceRow:hover {
	background-color: var(--invoice-mid);
}

.ManualRow {
	background-color: var(--purchases-light);
}
.ManualRow:hover {
	background-color: var(--purchases-mid);
}
.MobilRow {
	background-color: var(--message-light);
}
.MobilRow:hover {
	background-color: var(--message-mid);
}
.CreditRow {
	background-color: var(--balance-light);
}
.CreditRow:hover {
	background-color: var(--balance-mid);
}
.WithdrawalRow {
	background-color: var(--invoice-light);
}
.WithdrawalRow:hover {
	background-color: var(--invoice-mid);
}

.Dietail {
	background-color: transparent;
	color: var(--invoice);
	cursor: pointer;
	margin-left: 10px;
}

.DietailPanel {
	background-color: var(--invoice-light);
	padding: 5px;
	font-size: smaller;
	font-weight: normal;
}
.DietailRow {
	margin-top: 5px;
	align-items: flex-end;
	justify-content: space-between;
	margin-left: 30px;
	margin-right: 30px;
	border-bottom: 1px solid var(--invoice-mid);
}

.DefaultFilterButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	background-color: var(--message);
	color: var(--message-light);
	border-color: transparent;
	border-radius: 3px;
	margin: 1px;
}

.DefaultFilterButton:hover {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border-color: transparent;
}
.ResetFilterButton {
	background-color: var(--message);
	color: var(--message-light);
	border-color: transparent;
	border-radius: 3px;
	margin-right: 5px;
}

.ResetFilterButton:hover {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border-color: transparent;
}

.FilterButton {
	display: flex;

	justify-content: center;
	align-items: center;

	background-color: var(--message);
	color: var(--message-light);
	border-color: transparent;
	border-radius: 3px;
	margin-right: 5px;
}

.FilterButton:hover {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border-color: transparent;
}
.FilterButton:focus {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border-color: transparent;
}
.FilterButton:active {
	background-color: var(--message-dark) !important;
	color: var(--message-mid) !important;
	border-color: transparent !important;
}

.ActiveFilterButton {
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 3px;
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message);
}

.HeaderContainerClass {
	height: 245px;
	background-color: rgb(223, 228, 233);
	padding-top: 150px;
}
.DateLabel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-size: smaller;
}
.DateCol {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.BtnCol {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
.BtnColCsv {
	display: flex;
	justify-content: flex-end;
	margin-top: 5px;
	margin-bottom: 5px;
}
.labelCol {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* position: absolute;
	margin-top: 5px; */
}

.DateLabel {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: small;
	font-weight: bold;
}

@media (max-width: 992px) {
	.HeaderContainerClass {
		height: 335px;
		padding-top: 240px;
	}
	.BtnCol {
		align-items: center;
	}
}

@media (max-width: 768px) {
	.HeaderContainerClass {
		height: 335px;
		padding-top: 240px;
	}
}

@media (max-width: 576px) {
	.HeaderContainerClass {
		height: 395px;
		padding-top: 300px;
	}
	.DateLabel {
		visibility: hidden;
		position: absolute;
	}
	.labelCol {
		align-items: center;
	}
}
