.tooltip {
	position: absolute;
	text-align: center;
	padding: 5px;
	font: 12px sans-serif;
	background: var(--invoice-light);
	color: var(--invoice);
	border: 1px solid var(--invoice);
	border-radius: 5px;
	pointer-events: none;
}

.totalTooltip {
	position: absolute;
	text-align: center;
	padding: 5px;
	font: 12px sans-serif;
	background: var(--invoice);
	color: var(--invoice-light);
	border: 1px solid var(--invoice-dark);
	border-radius: 5px;
	pointer-events: none;
}
.circle {
	opacity: 0.8;
	fill: var(--invoice-mid);
	stroke: var(--invoice);
}

.circle:hover {
	fill: var(--invoice);
}

.tickLine {
	stroke: var(--invoice-mid);
}

.titleText {
	fill: var(--invoice-dark);
	font-size: x-large;
}
.tick {
	stroke: var(--invoice);
	opacity: 0.4;
}

.labelText {
	font-size: xx-small;
	font-weight: bold;
}
.rectlegend {
	stroke: var(--invoice-dark);
	opacity: 0.5;
	height: 15px;
}

.rectlegend:hover {
	stroke: var(--invoice-dark);
	opacity: 0.4;
	height: 15px;
	cursor: pointer;
}

.lineLegend:hover {
	fill: var(--invoice-mid);
	stroke: var(--invoice);
	cursor: pointer;
}
.circleLegend {
	fill: var(--invoice-mid);
	stroke: var(--invoice);
}
.circleLegend:hover {
	fill: var(--invoice-light);
	stroke: var(--invoice);
	cursor: pointer;
}
.purchaseCashIncome {
	stroke: var(--purchases);
	stroke-width: 5px;
}
.purchaseCashlessIncome {
	stroke: var(--purchases-dark);
	stroke-width: 5px;
}
.topUpCashIncome {
	stroke: var(--balance);
	stroke-width: 5px;
}
.topUpCashlessIncome {
	stroke: var(--balance-dark);
	stroke-width: 5px;
}
.purchaseCashPrice {
	stroke: var(--purchases);
	stroke-width: 5px;
}
.purchaseCashlessPrice {
	stroke: var(--purchases-dark);
	stroke-width: 5px;
}
.walletPrice {
	stroke: var(--message-dark);
	stroke-width: 5px;
}
