.NoPadding {
	padding: 0px;
}

.TopBottomPadding {
	padding-bottom: 1rem;
	padding-top: 0.5rem;
}

.HintLabel {
	border: 1px solid;
	border-color: #b9cfe8;
	background-color: #dfe4e9;
	padding: 1rem;
	margin-top: 1rem;
}

.RightAlign {
	text-align: right;
}

.BottomMargin {
	margin-bottom: 0.5rem;
}
