.HeaderCard {
	flex: 0 0 70%;
	min-width: 270px;
	max-width: 800px;
	padding-left: 10px;
	padding-right: 10px;
}

.InnerDiv {
	margin-top: 1rem;
}
