.NutInf {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.NutInfHeader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: large;
	text-align: center;
	margin-bottom: 0.5rem;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}
.EnergyeCont {
	text-align: center;
	background-color: WhiteSmoke;
	border-radius: 5px;
	margin: 0.15rem;
}
.AttrCont {
	text-align: center;
	background-color: WhiteSmoke;
	border-radius: 5px;
	margin: 0.1rem;
}

.TextBox {
	margin-bottom: 1rem;
}
.TextHeader {
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: large;
}
.TextTitle {
	display: flex;
	flex-direction: column;
	font-size: x-large;
	font-weight: 400;
	text-align: center;
}
.TextBody {
	font-size: small;
	font-weight: 600;
	margin-bottom: 1rem;
	margin-top: 1rem;
}
.AlergeneRow {
	align-items: center;
	justify-content: center;
	margin-bottom: 0.5rem;
}
.AlergenTitle {
	font-size: small;
	font-weight: 400;
}
.AlergenName {
	font-size: small;
	font-weight: 800;
}
.Price {
	font-size: x-large;
	font-weight: 900;
	text-align: center;
}
.ServingSize {
	font-size: large;
	font-weight: bold;
	text-align: center;
}
.LayoutPanelRow {
	align-items: center;
	justify-content: center;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
/*  Language Button in VMProductMatrix.js */
.LanguageButton {
	display: flex;
	align-items: center;
	background-color: transparent;
	border-color: transparent;
	border-radius: 40px;
	padding: 0.5rem 1rem;
	font-size: small;
	font-weight: 500;
	text-transform: uppercase;
	color: #515151;
	letter-spacing: 2px;
	box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
		-2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.LanguageButton:hover {
	background-color: transparent;
	border-color: transparent;
	color: #515151 !important;
	box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
		inset -2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1),
		inset 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
}
.LanguageButton:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #515151 !important;
	box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
		inset -2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1),
		inset 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.LanguageButton:focus {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #515151 !important;
	box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
		-2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.OnActiveLanguageButton {
	display: flex;
	align-items: center;
	background-color: transparent !important;
	border-color: transparent !important;
	border-radius: 40px;
	padding: 0.5rem 1rem;
	font-size: small;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #515151 !important;
	box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
		inset -2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1),
		inset 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.LanguageRow {
	margin: 2rem;
	justify-content: center;
	align-items: center;
}
.LanguagePanelRow {
	width: 100%;
	justify-content: center;
	align-items: center;
}

.LanguageText {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: x-large;
	font-weight: 600;
	margin: 1rem;
	color: #515151 !important;
}
.ModalPanel {
	display: flex;
	flex-direction: row;
}
.ModalBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0.5rem;
}

.ModalText {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0.25rem;
	font-size: small;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #515151 !important;
}

.ProductMatrix {
	border-radius: 10px;
	margin: 0.2rem;
	box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
		-2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.15);
}
/*  Language Button in VMProductMatrix.js */

.ButtonMargins {
	margin-left: 10px;
	margin-right: 10px;
}
