.NavigationButton {
	min-width: 200px;
	height: 60px;
	pointer-events: auto;
	border-radius: 8px;
	display: flex;
	justify-content: space-around;
	align-items: space-around;
	font-weight: bold;
	border: 2px solid #a0a0a0;
}

.ShowButton:hover {
	border: 1px solid #a0a0a0;
	border-bottom: 3px solid #a0a0a0;
	border-right: 3px solid #a0a0a0;
}

.ShowButton {
	min-width: 200px;
	height: 60px;
	pointer-events: auto;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: small;
	border: 2px solid #a0a0a0;
}

.NavigationButton:hover {
	border: 1px solid #a0a0a0;
	border-bottom: 3px solid #a0a0a0;
	border-right: 3px solid #a0a0a0;
}

.NavPanel {
	border: 1px solid #a0a0a0;
	border-left: 2px solid #a0a0a0;
	border-right: 2px solid #a0a0a0;
	margin-bottom: 10px;
	font-weight: bold;
	border-radius: 10px;
	font-size: small;
	background-color: #cfd5db;
	padding-top: 20px;
	padding-left: 10px;
	padding-right: 10px;
}

.CenterPanel {
	min-width: 200px;
	height: 60px;
}

.NavDate {
	min-width: 200px;
	height: 60px;
	padding-left: 25px;
	padding-right: 25px;
}

.DatePanel {
	height: 100%;
	border: 1px solid #a0a0a0;
	border-left: 2px solid #a0a0a0;
	border-right: 2px solid #a0a0a0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-weight: bold;
	border-radius: 10px;
	font-size: smaller;
	background-color: #cfd5db;
	padding-left: 10px;
	padding-right: 10px;
}

.Datelabel {
	padding: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Date {
	height: 21px;
	width: 45%;
	background-color: #f6f6f6;
	border: 1px solid #a0a0a0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1px;
}

.EventLabel {
	width: 50%;
	padding: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	margin-left: 20px;
}

.EventNum {
	width: 30%;
	height: 30px;
	background-color: #f6f6f6;
	border: 1px solid #a0a0a0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
	margin-top: 5px;
}

.EditButton {
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
}

.ErrorI {
	width: 25px;
	height: 25px;
	cursor: pointer;
	font-size: larger;
	margin: auto;
	background-color: #ff6347;
	border: 3px solid #ff4500;
	border-radius: 30px;
	display: inline-block;
	position: relative;
}

.InfoI {
	width: 25px;
	height: 25px;
	cursor: pointer;
	font-size: larger;
	margin: auto;
	background-color: #87cefa;
	border: 3px solid #4169e1;
	border-radius: 30px;
	display: inline-block;
	position: relative;
}

.WarningI {
	width: 25px;
	height: 25px;
	cursor: pointer;
	font-size: larger;
	margin: auto;
	background-color: #ffd700;
	border: 3px solid #ffa500;
	border-radius: 30px;
	display: inline-block;
	position: relative;
}

.SuccessI {
	width: 25px;
	height: 25px;
	cursor: pointer;
	font-size: larger;
	margin: auto;
	background-color: #90ee90;
	border: 3px solid #3cb371;
	border-radius: 30px;
	display: inline-block;
	position: relative;
}

.ScrollOverlay {
	text-align: center;
	padding-top: 270px;
	background-color: #aaaaaa75;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	vertical-align: middle;
	font-weight: 600;
	font-size: '48px';
}

.TableHeader {
	width: 100%;
	margin: auto;
	padding: 10px;
	background-color: #f6f6f6;
	border: 1px solid #cfd5db;
}

.MainPanel {
	width: 100%;
	margin: auto;
}

.ErrorBadge {
	height: 30px;
	width: 30px;
	background-color: #ff6347;
	color: #fff;
	text-align: center;
	padding: 1px;
	border-radius: 10px;
	border: 5px solid #ff4500;
	font-size: larger;
}

.SuccesBadge {
	height: 30px;
	width: 30px;
	background-color: #90ee90;
	color: #3cb371;
	text-align: center;
	padding: 1px;
	border-radius: 10px;
	border: 5px solid #3cb371;
	font-size: larger;
}

.InfoBadge {
	height: 30px;
	width: 30px;
	background-color: #87cefa;
	color: #4169e1;
	text-align: center;
	padding: 1px;
	border-radius: 10px;
	border: 5px solid #4169e1;
	font-size: larger;
}
.WarningBadge {
	height: 30px;
	width: 30px;
	background-color: #ffd700;
	color: #ffa500;
	text-align: center;
	padding: 1px;
	border-radius: 10px;
	border: 5px solid #ffa500;
	font-size: larger;
}

.ErrorLabel {
	padding: 1px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #ff6347;
	font-weight: bold;
	border: 3px solid #ff4500;
	border-radius: 5px;
	text-align: center;
}

.InfoLabel {
	padding: 1px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #87cefa;
	font-weight: bold;
	border: 3px solid #4169e1;
	border-radius: 5px;
	text-align: center;
}

.WarningLabel {
	padding: 1px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #ffd700;
	font-weight: bold;
	border: 3px solid #ffa500;
	border-radius: 5px;
	text-align: center;
}

.SuccessLabel {
	padding: 1px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #90ee90;
	font-weight: bold;
	border: 3px solid #3cb371;
	border-radius: 5px;
	text-align: center;
}

.Popover {
	border-radius: 10px;
}

.IBadge {
	cursor: pointer;
	font-size: 18px;
	color: var(--message);
	background-color: transparent;
	border-color: transparent;
}

.MFBadge {
	cursor: pointer;
	font-size: 18px;
	color: var(--inv-tuch-error);
	background-color: transparent;
	border-color: transparent;
}

.TableHeaderClass {
	padding-top: 10px;
	padding-bottom: 10px;
}

.FilterButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	background-color: var(--message);
	color: var(--message-light);
	border-color: transparent;
	border-radius: 3px;
	font-size: small;
	margin: 1px;
}

.FilterButton:hover {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border-color: transparent;
}

.FilterButton:active {
	background-color: var(--message-dark) !important;
	color: var(--message-mid) !important;
	border-color: transparent !important;
}

.HeaderContainerClass {
	height: 150px;
	background-color: rgb(223, 228, 233);
	padding-top: 100px;
}
@media (max-width: 1200px) {
	.HeaderContainerClass {
		height: 170px;
		padding-top: 120px;
	}
}

@media (max-width: 992px) {
	.HeaderContainerClass {
		height: 240px;
		padding-top: 190px;
	}
}

@media (max-width: 768px) {
	.HeaderContainerClass {
		height: 270px;
		padding-top: 200px;
	}
}

@media (max-width: 576px) {
	.HeaderContainerClass {
		height: 320px;
		padding-top: 250px;
	}
}
