// Here you can add other styles
.card-img-top {
	width: 120px;
	height: 120px;
}

.react-bs-table-gray-border > .react-bs-table {
	border: 1px solid #ddd;
}

.text-danger .dropdown-toggle::after {
	border-top-color: #db021d;
}

#loginButton {
	background-color: rgb(184, 0, 30);
	border: 0;
	width: 200px;
}

#regButton {
	border: 0;

	margin-bottom: 5px;
}

h2 {
	margin-top: 25%;
}

h4 {
	font-weight: 300;
	margin-bottom: 20px;
}

p#reg {
	font-size: 15px;
	text-align: center;
	line-height: 0.8;
}

#lang {
	width: 100px;
	margin-left: 75%;
}

#regLabel {
	color: white;
	text-align: center;
	margin-top: 100px;
}

#regInfo {
	color: white;
	text-align: center;
	font-size: 22px;
}

#forgetPass {
	margin-top: 10px;
	color: white;
	background-color: transparent;
	font-size: 17px;
}

#forgotButton1 {
	margin-top: 40px;
}

#forgotButton2 {
	border: 0;
	background-color: transparent;
	color: #db021d;
	margin-top: 50px;
}

#regColor {
	background-color: #db021d;
	border: 0;
}

#mainLogo {
	width: 100px;
	height: 100px;
}

#redButton {
	border: 0;
	background-color: #db021d;
	margin-top: 50px;
}

#redButton2 {
	border: 0;
	background-color: #db021d;
	margin-top: 5px;
	margin-left: 15px;
}

#gear {
	color: #db021d;
	font-size: 15px;
}

#userIcon {
	color: #db021d;
	font-size: 16px;
}

#idText {
	color: black;
	font-weight: 500;
	font-size: 15px;
}

#idActive {
	color: green;
	font-size: 15px;
}

//IKON BUTTONS TABLE
#delButton {
	max-width: 40px;
	font-size: 19px;
	color: rgb(254, 77, 77);
	background-color: transparent;
	border: 0;
}

#debugButton {
	max-width: 40px;
	font-size: 19px;
	color: rgb(110, 188, 62);
	background-color: transparent;
	border: 0px;
}

#editButton {
	max-width: 30px;
	font-size: 21px;
	color: rgb(110, 188, 62);
	background-color: transparent;
	border: 0;
}

#lcdRedButton {
	padding: 0;
	width: 40px;
	height: 40px;
	border: 2px solid white;
	background-color: #db021d;
	border-radius: 5px;
}

#lcdGrayButton {
	padding: 0;
	width: 40px;
	height: 40px;
	border: 2px solid white;
	background-color: rgb(87, 104, 124);
	border-radius: 5px;
}

#lcdTextButton {
	padding: 0;
	width: 40px;
	height: 40px;
	border: 2px solid white;
	background-color: #db021d;
	border-radius: 5px;
	font-size: 11px;
	line-height: 12px;
}

#lcdTextButton:hover {
	background-color: #ffc107;
}

#lcdGrayButton:hover {
	background-color: #ffc107;
}

#lcdRedButton:hover {
	background-color: #ffc107;
}

#lcdProgButton:hover {
	background-color: #ffc107;
}

#lcdProgButtonK:hover {
	background-color: #ffc107;
}

#lcdProgButtonS:hover {
	background-color: #ffc107;
}

#lcdProgButton {
	margin: 0 auto;
	border: 2px solid white;
	background-color: #db021d;
	border-radius: 5px;
	width: 100%;
	font-size: 14px;
}

#lcdProgButtonK {
	margin: 0 auto;
	border: 2px solid white;
	background-color: #db021d;
	border-radius: 5px;
	width: 100%;
	font-size: 14px;
}

#lcdProgButtonS {
	margin: 0 auto;
	border: 2px solid white;
	background-color: #db021d;
	border-radius: 5px;
	width: 134px;
	font-size: 14px;
}

#lcdLabel {
	color: white;
}

#cardHeight {
	height: 50px;
	padding: 10px;
}

#dashButton {
	max-width: 500px;
	background-color: transparent;
}

#tableNote {
	width: 10px;
}

#cardSnakky {
	padding-left: 172px;
	padding-right: 172px;
}

.Select-option,
.Select-value {
	font-weight: bold;
	color: black;
}

#editButton2 {
	max-width: 30px;
	font-size: 17px;
	color: #db021d;
	background-color: transparent;
	border: 0;
}

#userIcon2 {
	color: rgb(110, 188, 62);
	font-size: 15px;
}

#imgSetProm {
	display: inline-block;
	width: 150px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-x: clip;
}
@media only screen and (max-width: 600px) {
	#imgSetProm {
		width: 50px;
		margin: 0px !important;
	}
}

#imgSet {
	width: 100%;
	//height: 180px;
	margin-top: 10px;
	margin-bottom: 10px;
	//border: 1px solid lightgray;
	//padding: 10px;
	background-color: transparent;
}

#buttonSetPrice {
	border-radius: 17px;
	margin-top: 5px;
	padding-left: 25px;
	padding-right: 25px;
}

#promCardButton {
	width: 98%;
	margin-top: 5px;
	margin-bottom: 0px;
}

#promCardPrice {
	margin-left: 10px;
}

#promCardPromoted {
	text-align: left;
	color: indigo;
}

#deviceStatusH3 {
	margin-top: 20px;
}

#deviceStatusB {
	color: gray;
}

#deviceStatusIcon {
	font-size: 22px;
}

#deviceStatusText {
	font-size: 12px;
	font-weight: 500;
}

#userSettingsEmail {
	margin-top: 25px;
}

#logStatusIcon {
	font-size: 15px;
	text-align: center;
}

#progBarHeight {
	height: 80px;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: #dbdbdb;
	border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
	background: #bebebe;
}

::-webkit-scrollbar-track {
	background: transparent;
}
@media only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px) {
	::-webkit-scrollbar {
		display: none;
	}
}
