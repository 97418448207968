.Chart {
	text-align: center;
	display: block;
	border-radius: 5px;
	overflow: hidden;
	min-width: 250px;
	width: 33%;
}

.MachineDiv {
	display: inline-block;
	text-align: center;
	line-height: 30px;
	color: black;
}

.OkMachinesDiv {
	background-color: #bbd7c5;
}

.WarningMachinesDiv {
	background-color: #fce69b;
}

.ProgrammingMachinesDiv {
	background-color: #63c2de;
}

.MalfunctionMachinesDiv {
	background-color: #ea4234;
}

.OfflineMachinesDiv {
	background-color: #95a0b2;
}

.TopLegends {
	text-align: center;
	margin-bottom: 10px;
}
.TopLegends > span {
	margin-right: 10px;
	font-weight: 500;
}

.Legends {
	text-align: center;
}

.OkLegend > div {
	background-color: #bbd7c5;
}

.WarningLegend > div {
	background-color: #fce69b;
}

.ProgrammingLegend > div {
	background-color: #63c2de;
}

.MalfunctionLegend > div {
	background-color: #ea4234;
}

.OfflineLegend > div {
	background-color: #95a0b2;
}

.Legend {
	display: inline-block;
	height: 30px;
	width: 140px;
	margin-top: 10px;
}

.Legend > div {
	display: inline-block;
	border-radius: 5px;
	height: 20px;
	width: 20px;
}

.Legend > span {
	line-height: 20px;
	vertical-align: top;
}

.Label {
	display: flex;
	justify-content: center;
	margin: 5px;
	border-radius: 5px;
	line-height: 30px;
	color: var(--control-dark);
}
.LabelColTit {
	display: flex;
	justify-content: center;
	background-color: var(--control-light);
	width: 60%;
	margin: 5px;
	font-weight: bold;
	font-size: small;
	border-radius: 5px;
	border-width: 1px 0px 0px 1px;
	border-style: inset;
	border-color: var(--control-light);
}

.LabelColNum {
	display: flex;
	justify-content: center;
	font-weight: bold;
}
