.absoluteDiv {
	left: 0;
	right: 0;

	margin-left: auto;
	margin-right: auto;

	position: absolute;

	z-index: 2000;
	pointer-events: none;
}

.toastStyle {
	left: 0;
	right: 0;

	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.DeleteButton {
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
}

.EditButton {
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
}
.EditButton:hover {
	color: white;
	background-color: #6ebc3e;
}