.machine-led-background {
  background-color: #78909c;
  color: white;
}

@media (min-width: 1200px) {
  .machine-container {
    width: 900px;
    font-size: 16px;
  }
  .machine-lcd-40 {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .machine-container {
    width: 680px;
    font-size: 14px;
  }
  .machine-lcd-40 {
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .machine-container {
    width: 650px;
    font-size: 12px;
  }
  .machine-lcd-40 {
    font-size: 9px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .machine-container {
    width: 480px;
    font-size: 10px;
  }
  .machine-lcd-40 {
    font-size: 6px;
  }
}

@media (max-width: 575px) {
  .machine-container {
    width: 100%;
    font-size: 8px;
  }
  .machine-lcd-40 {
    font-size: 8px;
  }
}
