.TableHeader {
	background-color: var(--purchases-mid);
	color: var(--purchases);
	border-radius: 10px;
}

/* .TableContainer {
	height: 330px;
}

.TableContainerClass {
	height: 310px;
} */

.TableBody {
	color: var(--purchases-dark);
}

.TableBody:hover {
	color: var(--purchases);
	background-color: var(--purchases-mid);
}

.RefundButton {
	color: var(--inv-tuch-error);
	background-color: var(--purchases);
	border-radius: 3px;
	border-color: transparent;
}

.RefundButton:hover {
	color: var(--purchases);
	background-color: var(--invoice);
}

.RefundButton:active {
	color: var(--purchases) !important;
	background-color: var(--invoice) !important;
}

.RefundedButton {
	color: var(--purchases);
	background-color: var(--purchases-light);
	border-radius: 10px;
	border-color: transparent;
	font-size: large;
}

.Date {
	font-size: small;
	font-weight: bold;
}
