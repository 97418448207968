.DeleteButton {
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.EditButton {
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}
.EditButton:hover {
	color: white;
	background-color: #6ebc3e;
}

.DeactivateButton {
	color: #800000;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.DeactivateButton:hover {
	color: white;
	background-color: #800000;
	border-color: transparent;
}

.ActivateButton {
	color: #4682B4;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.ActivateButton:hover {
	color: white;
	background-color: #4682B4;
	border-color: transparent;
}

.DescriptionButton {
	color: #FFA500;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.DescriptionButton:hover {
	color: white;
	background-color: #FFA500;
	border-color: transparent;
}