.klix-bg {
  background-color: #424242;
}

.klix-led {
  background-color: #2c30ef;
  p {
    color: white;
  }
}

.klix-led-arrow {
  padding-right: 5px;
  padding-left: 5px;
}

@media (min-width: 1200px) {
  .klix {
    width: 900px;
    font-size: 16px;
  }
  .klix-lcd-text {
    font-size: 14px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .klix {
    width: 680px;
    font-size: 12px;
  }
  .klix-lcd-text {
    font-size: 8px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .klix {
    width: 650px;
    font-size: 12px;
  }
  .klix-lcd-text {
    font-size: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .klix {
    width: 480px;
    font-size: 10px;
  }
  .klix-lcd-text {
    font-size: 5px;
  }
}

@media (max-width: 575px) {
  .klix {
    width: 100%;
    font-size: 8px;
  }
  .klix-lcd-text {
    font-size: 8px;
  }
}
