.LanguageBlock {
	display: flex;
	justify-content: flex-end;
}

.LanguageIcon {
	margin-top: 50px;
}

.LanguageCombo {
	margin-top: 50px;
}

.PanelLeft {
	border-radius: 10px 0px 0px 10px;
}

.PanelRight {
	border-radius: 0px 10px 10px 0px;
}
