.HeaderBackground {
	background-color: #444d58;
	color: white;
	text-align: center;
	font-size: 18px;
}

.CardBody {
	padding: 0.75rem;
}
