@font-face {
  font-family: 'Winstar_LCD_EU';
  font-style: normal;
  font-weight: bold;
  src: local('Winstar_LCD_EU'), url(./Winstar_LCD_EU.ttf) format('truetype');
}

@font-face {
  font-family: 'Winstar_LCD_CP';
  font-style: normal;
  font-weight: bold;
  src: local('Winstar_LCD_CP'), url(./Winstar_LCD_CP.ttf) format('truetype');
}

.lcd_font_cp {
  font-family: 'Winstar_LCD_CP';
  color: #000000;
  font-weight: 100;
}

.lcd_font {
  font-family: 'Winstar_LCD_EU';
  color: #000000;
  font-weight: 100;
}
