.DeleteButton {
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
}

.EditButton {
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
}
.EditButton:hover {
	color: white;
	background-color: #6ebc3e;
}
.FormGroup {
	padding: 5px;
	margin: auto;
}

.FormGroupTable {
	padding: 5px;
	margin: auto;
}

.FormGroupTable:hover {
	box-shadow: 2px 2px 5px Gainsboro;
}

.Label {
	padding: 5px;
}
