.TableHeader {
	background-color: var(--invoice-mid);
	color: var(--invoice);
	border-radius: 10px;
}

/* .TableContainer {
	height: 330px;
}

.TableContainerClass {
	height: 300px;
} */

.TableBody {
	color: var(--invoice-dark);
}

.TableBody:hover {
	color: var(--invoice);
	background-color: var(--invoice-mid);
}

.ApproveButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	color: var(--balance-mid);
	background-color: var(--balance);
	border-radius: 3px;
	border-color: transparent;
}

.ApproveButton:hover {
	color: var(--balance-light);
	background-color: var(--balance-dark);
}

.ApproveButton:active {
	color: var(--balance-light) !important;
	background-color: var(--balance) !important;
}

.DeclainButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	color: var(--invoice-mid);
	background-color: var(--invoice);
	border-radius: 3px;
	border-color: transparent;
}

.DeclainButton:hover {
	color: var(--invoice-light);
	background-color: var(--invoice-dark);
}

.DeclainButton:active {
	color: var(--invoice-light) !important;
	background-color: var(--invoice) !important;
}

.ApprovaledButton {
	color: var(--balance);
	background-color: transparent;
	border-radius: 10px;
	border-color: transparent;
	font-size: large;
	padding-top: 10px;
}

.DisplayButton {
	font-weight: bold;
	color: var(--message);
	background-color: transparent;
	border-radius: 10px;
	border-color: transparent;
}

.DisplayButton:hover {
	color: var(--message-light);
	background-color: var(--message);
	border-color: var(--message-light);
}

.Date {
	font-size: small;
	font-weight: bold;
}
