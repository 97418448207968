.SlaveKeyboardContainer {
	width: 100%;
	font-size: 16px;
}
.Overlay {
	width: 100%;
	height: 100%;
	background-color: #4a4444d9;
	display: block;
	position: absolute;
	z-index: 100;
}

.RelativeRow {
	position: relative;
}
