.TableHeaderClass {
	padding-top: 10px;
	padding-bottom: 10px;
}
.HeaderContainerClass {
	height: 170px;
	background-color: rgb(223, 228, 233);
	padding-top: 80px;
}
.MobilRow {
	background-color: var(--message-light);
}
.MobilRow:hover {
	background-color: var(--message-mid);
}
.SummaryRow {
	margin-top: 5px;
	align-items: flex-end;
	justify-content: space-between;
	font-size: medium;
	font-weight: bold;
	margin-left: 30px;
	padding-bottom: 5px;
	border-bottom: 1px solid var(--control-mid);
}

.DetailsButton {
	font-size: x-large;
	color: var(--message);
	border: '0px';
}
.DetailsButton:hover {
	color: var(--message-dark);
}
.TableToolbar {
	margin-top: 10px;
	align-items: flex-end;
	justify-content: flex-end;
	font-size: medium;
	font-weight: bold;
	margin-left: 30px;
	margin-right: 30px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--control-mid);
}
.ExportExcelButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	border-radius: 3px;
	color: var(--message-light);
	background-color: var(--message);
	font-size: small;
	margin: 1px;
}
.ExportExcelButton:hover {
	color: var(--message-mid);
	background-color: var(--message-dark);
}
.ExportExcelButton:active {
	color: var(--message-mid) !important;
	background-color: var(--message-dark) !important;
}

.FilterButton {
	background-color: var(--message);
	color: var(--message-light);
	border-color: transparent;
	border-radius: 3px;
	margin-right: 5px;
}

.FilterButton:hover {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border-color: transparent;
}

.ActiveFilterButton {
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message);
	border-radius: 3px;
	margin-right: 5px;
}
.btnCol {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin: 5px;
}

/* @media (max-width: 1000px) {
	.HeaderContainerClass {
		height: 200px;
		padding-top: 110px;
	}
}

@media (max-width: 768px) {
	.HeaderContainerClass {
		height: 220px;
		padding-top: 130px;
	}
}*/

@media (max-width: 576px) {
	.btnCol {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.HeaderContainerClass {
		height: 220px;
		padding-top: 130px;
	}
	.ExportExcelButton {
		margin: 5px;
	}
}
