.DateParagraph {
	margin-bottom: 0px;
}

.IconDiv {
	display: inline-block;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	left: 3rem;
}

.TextDiv {
	display: inline-table;
	margin-left: 2rem;
}
