.react-bs-table-pagination {
	display: flex;
	justify-content: center;
	background-color: transparent !important;
	border-radius: 10px;
	opacity: 0.5;
}
.pagination {
	display: flex;
}

.page-link {
	padding: 15px !important;
	color: var(--control-dark);
	background-color: transparent !important;
	border-color: transparent !important;
	font-weight: bold;
}
.page-item .page-link {
	box-shadow: none;
}

.page-item.active .page-link {
	color: var(--label-red);
	background-color: transparent !important;
	margin-left: 0;
	box-shadow: none;
}

.active.page-link {
	color: var(--label-red) !important;
}

.page-link:hover {
	color: var(--label-red-dark);
	border-color: transparent !important;
}
