.HeaderCard {
	flex: 0 0 50%;
	min-width: 270px;
	padding-left: 10px;
	padding-right: 10px;
}

.RootCard {
	word-wrap: break-word;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid #c8ced3;
	border-radius: 0.25rem;
	margin-bottom: 10px;
}

.EventListCard {
	flex: 0 0 95%;
	margin: 10px;

	min-width: 800px;
}

.RootCardMachines {
	width: 750px;
	margin: 10px;
}

.Row {
	margin: 0px;
}

.NotificationCard {
	flex: 0 0 55%;
	min-width: 400px;
	margin-bottom: 0px;
	height: 640px;
}

.MachineMapCard {
	flex: 0 0 97%;
	min-width: 500px;
	margin: 10px;
	margin-bottom: 14px;
}

.TopLeftBlock {
	flex: 0 0 40%;
	margin: 10px;
	min-width: 550px;
	margin-top: 0px;
	margin-bottom: 0px;
}
