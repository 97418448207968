.CardPanel {
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	background-color: var(--balance-mid);
	border-radius: 10px;
	padding: 10px;
	margin-top: 10px;
}

.FormPanel {
	padding-top: 5px;
}

.Label {
	color: var(--balance-dark);
	font-weight: bold;
	font-size: larger;
}

.Balance {
	padding-top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	border-radius: 10px;
	background-color: #fff;
	font-weight: bold;
	font-size: larger;
	border-color: transparent;
}

.Send {
	width: 7rem;
	border-radius: 3px;
	color: var(--balance-light);
	background-color: var(--balance) !important;
	border-color: var(--balance-dark) !important;
}

.Send:hover {
	background-color: var(--balance-dark) !important;
	border-color: var(--balance) !important;
}

.TableHeader {
	background-color: var(--balance-mid);
	color: var(--balance);
	border-radius: 3px;
}
/* 
.TableContainer {
	height: 360px;
}
.TableContainerClass {
	height: 310px;
} */

.TableBody {
	color: var(--balance-dark);
}

.TableBody:hover {
	color: var(--balance);
	background-color: var(--balance-mid);
}

.Date {
	font-size: small;
	font-weight: bold;
}

.TitleButton {
	width: 7rem;
	margin: 5px;
	color: var(--balance-mid);
	background-color: var(--balance);
	font-weight: bold;
	border-radius: 3px;
	border-color: transparent;
}

.TitleButton:hover {
	color: var(--balance-light);
	background-color: var(--balance-dark);
}
.TitleButton:focus {
	color: var(--balance-light);
	background-color: var(--balance-dark);
}

.BtnRow {
	justify-content: center;
	align-items: center;
}
