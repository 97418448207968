.OnTelemetry {
	background-color: var(--invoice);
	color: var(--invoice-mid);
	display: flex;
	justify-content: center;
	align-items: center;
}
.OnTelemetry:hover {
	background-color: var(--invoice-mid);
	color: var(--invoice);
}

.OnRemote {
	background-color: var(--purchases);
	color: var(--purchases-mid);
	display: flex;
	justify-content: center;
	align-items: center;
}
.OnRemote:hover {
	background-color: var(--purchases-mid);
	color: var(--purchases);
}

.OnIvtouch {
	background-color: var(--message);
	color: var(--message-mid);
	display: flex;
	justify-content: center;
	align-items: center;
}
.OnIvtouch:hover {
	background-color: var(--message-mid);
	color: var(--message);
}
