.NavigationButton {
	width: 60px;
	height: 30px;
	pointer-events: auto;
}

.EditButton {
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
}

.HintBlock {
	cursor: pointer;
}

.HintBlock > i {
	color: #6ebc3e;
}

.ScrollOverlay {
	text-align: center;
	padding-top: 270px;
	background-color: #aaaaaa75;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	vertical-align: middle;
	font-weight: 600;
	font-size: '48px';
}
