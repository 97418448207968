.FilterDiv {
	display: inline-block;
	height: 42px;
}

.FilterClearButton {
	width: 2.5rem;
	padding-bottom: 9px;
	margin-bottom: 3px;
	height: 35px;
	border: 1px #b0c4de solid;
}

.filterButton {
	width: 99%;
	/* vertical-align: top; 
	margin-right: 0.25rem; */
}

.FilterClearInput {
	min-width: 100px;
}

.FirstSwitchButton {
	/* margin-left: 0.25rem; */
}
.SwitchButton {
	width: 9rem;
	vertical-align: top;

	background-color: transparent;
	color: #4dbd74;
}

.SwitchButtonSelected {
	width: 8rem;
	vertical-align: top;

	background-color: #4dbd74;
	color: white;
}

.ParentDiv {
	width: 100% !important;
}
