.ProductCard {
	margin: 0.2rem;
	border-color: rgba(225, 225, 225, 0.5) !important;
	/* border-color: transparent !important; */
	box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
		-2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.ProductCard:hover {
	margin: 0.2rem;
	border-color: rgba(225, 225, 225, 0.5) !important;
	box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
		inset -2px -2px 12px rgba(255, 255, 255, 0.5),
		inset 2px 2px 4px rgba(255, 255, 255, 0.1),
		inset 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.ProductHeader {
	display: inherit;
	overflow-x: 'clip';
	overflow-y: 'clip';
	width: '100%';
	height: '81px';
}

.ThinMargin {
	padding: 0.5rem;
}

@media only screen and (max-width: 600px) {
	.MobileVisibility {
		display: none !important;
	}
	.ThinMargin {
		padding: 0rem;
	}
}
