.tab-content {
	border: 0px;
}

.DefaultButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	border-radius: 3px;
}

.DefaultButtonMd {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	border-radius: 3px;
}

.DefaultButtonLg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 11rem;
	border-radius: 3px;
}
.DefaultButtonXl {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 13rem;
	border-radius: 3px;
}

.DefaultButtonXxl {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 17rem;
	border-radius: 3px;
}

.form-control {
	border-color: #b9cfe8;
}

.react-bs-table-tool-bar {
	height: 0px;
	margin-bottom: 0px !important;
}

.nav-item {
	min-width: 80px !important;
}

.notification-yellow {
	color: #ffde75 !important;
}

.notification-green {
	color: #80c155 !important;
}

.notification-blue {
	color: #75a3ff !important;
}

.notification-red {
	color: #f95c74 !important;
}

.card {
	border-radius: 10px;
}

.tooltip-inner {
	pointer-events: none;
}
