.popover .arrow {
	display: none !important;
}

.popover {
	margin-left: 30px;
	border-radius: 10px;
	border: 2px solid #a0a0a0;
	background-color: #a0a0a0;
	box-shadow: 5px 5px 10px grey;
}

.popover-header {
	border-radius: 10px 10px 0px 0px;
	background-color: #a0a0a0;
	margin: 1px;
	font-weight: bold;
}

.popover-body {
	border-radius: 0px 0px 10px 10px;
	background-color: #c6c6c6;
	margin: 1px;
	font-weight: bold;
}
