.pagination-datatables,
.pagination {
	// li {
	// 	@extend .page-item;

	// 	a {
	// 		@extend .page-link;
	// 	}
	// }
}

.label-pill {
	border-radius: 1rem !important;
}

// temp fix for Vue & React

// Open state for the dropdown
.open,
.show {
	// Remove the outline when :focus is triggered
	> a {
		outline: 0;
	}
}

// navbar dropdown fix
.navbar .dropdown-toggle {
	@extend .nav-link;

	.img-avatar {
		height: $navbar-height - 20px;
		margin: 0 10px;
	}
}

.card-block {
	@extend .card-body;
}
