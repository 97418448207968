.CardContainer {
	display: flex;
	flex-wrap: wrap;
	flex: 0 0 100%;
	justify-content: center;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	overflow: hidden;
}
