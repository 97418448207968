// core overrides

$border-color: $gray-200;
$navbar-brand-logo-size: 50px auto;

// Navbar

$navbar-height: 60px;
$navbar-brand-width: 220px;

// Sidebar

$sidebar-width: 220px;
$mobile-sidebar-width: 220px;

// Sidebar Navigation

$sidebar-nav-color: #fff;
$sidebar-nav-title-padding-y: 1rem;
$sidebar-nav-title-padding-x: 1.25rem;
$sidebar-nav-link-padding-y: 1rem;
$sidebar-nav-link-padding-x: 1.25rem;

// Sidevar active-hover custom

$sidebar-nav-link-hover-bg: #cd5c5c;
$sidebar-nav-link-hover-icon-color: #fff;
$sidebar-nav-link-active-bg: theme-color('primary');
$sidebar-nav-link-active-icon-color: #fff;

// Aside

$aside-menu-nav-padding-y: 1rem;
$aside-menu-nav-padding-x: 1.25rem;
