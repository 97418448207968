.TitleButton {
	width: 9rem;
	border-radius: 3px;
	color: var(--message-mid);
	background-color: var(--message);
	font-weight: bold;
}

.TitleButton:hover {
	color: var(--message-light);
	background-color: var(--message-dark);
}
.TitleButton:focus {
	color: var(--message-light);
	background-color: var(--message-dark);
}

.MessagesRow {
	height: 100%;
}

.MessagePanel {
	background-color: var(--message-mid);
	border-radius: 10px;
}

.TableHeader {
	background-color: var(--message-mid);
	color: var(--message);
	border-radius: 10px;
}

.TableBody {
	color: var(--message-dark);
}

/* .TableContainerClass {
	height: 280px;
} */
.Data {
	font-size: smaller;
	font-weight: bold;
}

.SubjectPanel {
	background-color: var(--message-light);
	border-radius: 10px;

	font-size: small;
	font-weight: bold;
}

.Subject {
	padding-top: 3px;
	font-size: small;
	font-weight: bold;
	color: var(--message-dark);
}

.MessageHeader {
	justify-content: space-between;
	align-items: center;
	color: var(--message);
	font-weight: bold;
	padding: 10px;
	margin: auto;
}
.MessageLabel {
	color: var(--message);
	font-weight: bold;
	padding-top: 10px;
}

.SubjectLabel {
	color: var(--message);
	font-weight: bold;
	margin: auto;
}

.MessageBody {
	background-color: var(--message-light);
	overflow-y: scroll;
	border-radius: 10px;
	height: 80%;
}

.Message {
	color: var(--message-dark);
	font-weight: bold;
	font-size: smaller;
}

.AdField {
	border-radius: 10px !important;
	background-color: var(--message-light) !important;
	border-color: transparent !important;
	color: var(--message-dark);
	font-weight: bold;
	font-size: smaller;
}

.MessageRow {
	cursor: 'pointer';
	align-items: center;
	justify-content: space-around;
}

.MessageRowOpen {
	cursor: 'pointer';
	align-items: center;
	justify-content: space-around;
	background-color: var(--message-dark);
	color: var(--message-mid);
}

.MessageRow:hover {
	background-color: var(--message-mid);
	color: var(--message);
}

.Send {
	width: 7rem;
	border-radius: 3px;
	color: var(--message-light);
	background-color: var(--message) !important;
	border-color: var(--message-dark) !important;
}

.Send:hover {
	background-color: var(--message-dark) !important;
	border-color: var(--message) !important;
	color: var(--message-mid);
}
