.DeleteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.EditButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}
.EditButton:hover {
	color: white;
	background-color: #6ebc3e;
}

.DeactivateButton {
	color: #800000;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.DeactivateButton:hover {
	color: white;
	background-color: #800000;
	border-color: transparent;
}

.ActivateButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 9rem;
	color: #4682b4;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.ActivateButton:hover {
	color: white;
	background-color: #6b9bc3;
	border-color: transparent;
}

.ActivateButton:active {
	color: white !important;
	background-color: #386890 !important;
	border-color: transparent !important;
}
.ActivateButtonActive {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 9rem;
	color: white;
	background-color: #386890;
	border-color: transparent;
}

.ActivateButtonActive:hover {
	color: white;
	background-color: #6b9bc3;
	border-color: transparent;
}

.ActivateButtonActive:focus {
	color: white;
	background-color: #386890;
	border-color: transparent;
}
.ActivateButtonActive:active {
	color: white !important;
	background-color: #386890 !important;
	border-color: transparent !important;
}

.DescriptionButton {
	color: #ffa500;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.DescriptionButton:hover {
	color: white;
	background-color: #ffa500;
	border-color: transparent;
}

.ActiveBackGround {
	background-color: #eef4f8;
}
