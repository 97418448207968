.DateParagraph {
	margin-bottom: 0px;
}

.IconDiv {
	display: inline-block;
	transform: translate(50%, 50%);
	width: 12px;
}

.TextDiv {
	cursor: pointer !important;
	display: inline-table;
	margin-left: 2rem;
}
