.ActivateButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 9rem;
	color: var(--balance);
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}

.ActivateButton:hover {
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: transparent;
}

.ActivateButton:focus {
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: transparent;
}

.ActivateButton:focus {
	color: var(--balance-light) !important;
	background-color: var(--balance) !important;
	border-color: transparent !important;
}

.DeleteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}
.EditButton {
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
	text-align: left;
}
.EditButton:hover {
	color: white;
	background-color: #6ebc3e;
}
