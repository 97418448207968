.dateSpan {
	margin-left: 10px;
}

.eventSpan {
	float: right;
	display: inline-block;
}

.eventSign {
	position: relative;
	z-index: 2;
}
.eventBubble {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 32px;
	height: 32px;
	margin-left: -7px;
	color: #444d56;
	align-items: center;
	background-color: #e1e4e8;
	border: 2px solid #fff;
	border-radius: 50%;
	text-align: center;

	padding: 2px;
}
.addSign {
	color: #6ebc3e;
}

.removeSign {
	color: red;
}

.stateSign {
	color: #2eadd3;
}

.bodyMargin {
	margin-left: 36px;
}

.detailMargin {
	margin-left: 10px;
}

.cardPadding {
	padding: 0.5rem;
}

.historyCard {
	margin-bottom: 0;
	border: 0;
}

.historyCard:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 16px;
	display: block;
	width: 2px;
	content: '';
	background-color: #e1e4e8;
}

.titleMargin {
	margin-bottom: 0rem;
}
