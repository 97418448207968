.Check {
	margin-bottom: 5px;
	display: flex;
	flex-direction: column;
}

.Col {
	background-color: ghostwhite;
	min-height: 80px;
}

.ColEdit {
	background-color: white;
	min-height: 80px;
}

.Machineselector {
	margin-top: 30px;
}
