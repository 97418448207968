.IconDiv {
	display: inline-block;
	top: 50%;
	width: 1rem;
	text-align: center;
}

.TextDiv {
	display: inline-table;
	margin-left: 2rem;
}

.CardHeader {
	background-color: unset;
	border-bottom: unset;
	padding-top: 0rem !important;
	padding-bottom: 0rem !important;
}

.MainDiv {
	padding-left: 5rem;
	padding-right: 1.5rem;
	padding-top: 1rem;
}

.HR {
	margin-bottom: 10px;
	margin-top: 15px;
}
