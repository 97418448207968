.scrollBox {
	height: 30rem;
	overflow-y: scroll;
	padding-right: 1rem;
	margin-right: -1rem;
}

.noRightPadding {
	padding-right: 0rem;
}
