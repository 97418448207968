.ButtonGroup {
	justify-content: center;
	margin: 10px;
}
.ChartButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 11rem;
	background-color: var(--message);
	color: var(--message-light);
	border-radius: 3px;
	border: 1px solid var(--message-light);
}

.ChartButton:focus {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border: 1px solid var(--message-mid) !important;
}
.ChartButton:hover {
	background-color: var(--message-dark);
	color: var(--message-mid);
	border: 1px solid var(--message-mid) !important;
}
.ChartButton:active {
	background-color: var(--message-mid) !important;
	color: var(--message) !important;
	border: 1px solid var(--message) !important;
}
.ChartButtonActive {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 11rem;
	background-color: var(--message-mid);
	color: var(--message);
	border-radius: 3px;
	border: 1px solid var(--message);
}
.ChartButtonActive:hover {
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message);
}

.FilterRow {
	justify-content: space-between;
	background-color: var(--message-light);
	color: var(--message);
	padding: 10px;
	border: 1px solid var(--message-mid);
}
.PanelRow {
	justify-content: space-around;
	align-items: center;
}
.PanelRowDown {
	justify-content: space-between;
	align-items: center;
}
.BtnPanelRow {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
}
.DateRow {
	justify-content: center;
	align-items: center;
	padding: 5px;
}

.DeviceFilterButtonOff {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	background-color: var(--message);
	color: var(--message-mid);
	border: 1px solid var(--message-mid);
	border-radius: 3px;
	margin-top: 10px;
	font-size: small;
}

.DeviceFilterButtonOff:hover {
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message) !important;
}
.DeviceFilterButtonOff:focus {
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message) !important;
}
.DeviceFilterButtonOff:active {
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message) !important;
}

.DeviceFilterButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message);
	border-radius: 3px;
	margin-top: 10px;
	font-size: small;
}

.DeviceFilterButton:hover {
	background-color: var(--message);
	color: var(--message-mid);
	border: 1px solid var(--message-mid) !important;
}
.DeviceFilterButton:focus {
	background-color: var(--message);
	color: var(--message-mid);
	border: 1px solid var(--message-mid) !important;
}
.DeviceFilterButton:active {
	background-color: var(--message) !important;
	color: var(--message-mid) !important;
	border: 1px solid var(--message-mid) !important;
}

.ClearButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message);
	border-radius: 3px;
	margin-top: 10px;
}

.ClearButton:hover {
	background-color: var(--message);
	color: var(--message-mid);
	border: 1px solid var(--message-mid) !important;
}

.ClearButton:active {
	background-color: var(--message);
	color: var(--message-mid);
	border: 1px solid var(--message-mid) !important;
}

.FilterButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9rem;
	background-color: var(--message-mid);
	color: var(--message);
	border: 1px solid var(--message);
	border-radius: 3px;
	margin-top: 10px;
}
.FilterButton:active {
	background-color: var(--message) !important;
	color: var(--message-mid) !important;
	border: 1px solid var(--message) !important;
}
.FilterButton:focus {
	background-color: var(--message);
	color: var(--message-mid);
	border: 1px solid var(--message) !important;
}

.FilterButton:hover {
	background-color: var(--message);
	color: var(--message-mid);
	border: 1px solid var(--message) !important;
}

.FilterInput {
	border: 1px solid var(--message);
	border-radius: 3px;
	color: var(--message) !important;
}

.QVBtn {
	background-color: var(--invoice);
	color: var(--invoice-light);
	border: 1px solid var(--invoice-dark);
	margin-left: 20px;
	border-radius: 50px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.QVBtn:hover {
	background-color: var(--invoice-dark);
	color: var(--invoice-mid);
	border: 1px solid var(--invoice);
}
.QVBtn:focus {
	background-color: var(--invoice-dark);
	color: var(--invoice-mid);
	border: 1px solid var(--invoice) !important;
}
.QVBtn:active {
	background-color: var(--invoice-dark) !important;
	color: var(--invoice-mid) !important;
	border: 1px solid var(--invoice) !important;
}
@media (max-width: 576px) {
	.BtnPanelRow {
		align-items: center;
	}
}
