.nav-tabs {
	.nav-link {
		color: $gray-600;
		&:hover {
			cursor: pointer;
		}
		&.active {
			color: $gray-800;
			background: #c7cacd;
			border-color: $border-color;
			border-bottom-color: #fff;
			&:focus {
				background: #fff;
				border-color: $border-color;
				border-bottom-color: #fff;
			}
		}
	}
}

.tab-content {
	margin-top: -1px;
	background: #fff;
	border: 1px solid $border-color;
	.tab-pane {
		padding: $spacer;
	}
}

.card-block {
	.tab-content {
		margin-top: 0;
		border: 0;
	}
}
