.BalanceButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	border-radius: 3px;
	color: var(--balance);
	background-color: var(--balance-light);
	border-color: var(--balance);
	border: 1px solid;
}

.BalanceButton:hover {
	color: var(--balance-light);
	background-color: var(--balance);
	border-color: var(--balance-light);
}

.BalanceButton:active {
	color: var(--balance-light) !important;
	background-color: var(--balance) !important;
	border-color: var(--balance-light) !important;
}

.BalanceButton:focus {
	color: var(--balance-light) !important;
	background-color: var(--balance) !important;
	border-color: var(--balance-light) !important;
}

.InvoliceButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	border-radius: 3px;
	color: var(--invoice);
	background-color: var(--invoice-light);
	border-color: var(--invoice);
	border: 1px solid;
}

.InvoliceButton:hover {
	color: var(--invoice-light);
	background-color: var(--invoice);
	border-color: var(--invoice-light);
}

.InvoliceButton:active {
	color: var(--invoice-light) !important;
	background-color: var(--invoice) !important;
	border-color: var(--invoice-light) !important;
}

.InvoliceButton:focus {
	color: var(--invoice-light) !important;
	background-color: var(--invoice) !important;
	border-color: var(--invoice-light) !important;
}

.MessageButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	border-radius: 3px;
	color: var(--message);
	background-color: var(--message-light);
	border-color: var(--message);
	border: 1px solid;
}

.MessageButton:hover {
	color: var(--message-light);
	background-color: var(--message);
	border-color: var(--message-light);
}

.MessageButton:active {
	color: var(--message-light) !important;
	background-color: var(--message) !important;
	border-color: var(--message-light) !important;
}

.MessageButton:focus {
	color: var(--message-light) !important;
	background-color: var(--message) !important;
	border-color: var(--message-light) !important;
}

.PurchasesButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7rem;
	margin: 0.25rem;
	border-radius: 3px;
	color: var(--purchases);
	background-color: var(--purchases-light);
	border-color: var(--purchases);
	border: 1px solid;
}

.PurchasesButton:hover {
	color: var(--purchases-light);
	background-color: var(--purchases);
	border-color: var(--purchases-light);
}

.PurchasesButton:active {
	color: var(--purchases-light) !important;
	background-color: var(--purchases) !important;
	border-color: var(--purchases-light) !important;
}

.PurchasesButton:focus {
	color: var(--purchases-light) !important;
	background-color: var(--purchases) !important;
	border-color: var(--purchases-light) !important;
}
