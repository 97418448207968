.absoluteDiv {
	left: 0;
	right: 0;

	margin-left: auto;
	margin-right: auto;

	position: absolute;

	z-index: 2000;
	pointer-events: none;
}

.toastStyle {
	left: 0;
	right: 0;

	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
