.DeleteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #db021d;
	background-color: transparent;
	border-color: transparent;
}

.EditButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 7rem;
	color: #6ebc3e;
	background-color: transparent;
	border-color: transparent;
}

.EditButton:hover {
	color: white;
	background-color: #6ebc3e;
}
