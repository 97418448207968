.nav-item.nav-link.active {
	background-color: #eff3f8 !important;
}

.fade.tab-pane.active.show {
	background-color: #eff3f8 !important;
}

.tab-content {
	background-color: #eff3f8 !important;
	min-height: 510px;
}
