.Header {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: xx-large;
	font-weight: bold;
	color: var(--inv-tuch-error);
}

.Text {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: large;
	color: var(--inv-tuch-error);
}

.Card {
	background-color: var(--invoice-light);
}
.Logo {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--inv-tuch-error);
	margin: 10px;
	padding: 10px;
}
