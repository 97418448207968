.ToolbarDiv {
	text-align: right;
	margin-top: 1rem;
}

.DeleteButton {
	margin-right: 10px;
}

.datePicker {
	width: 100%;
}
