.cardHeader {
	cursor: pointer;
	color: white;
	background-color: #2eadd3;
}
.cardHeader > i {
	margin-right: 1rem;
}

.card {
	margin-bottom: 0px;
}
.tabContent {
	border: 0px;
	padding: 5px;
	padding-bottom: 20px;
}

.navTabs {
	border-color: #bec1c4;
}
