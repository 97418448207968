.StateBtnDiv {
	width: 13.5rem;
	height: 32px;
	border: 1px solid #808080;
	border-radius: 3px;
	background-color: #808080;
	display: flex;
	justify-content: start;
}

.ActiveStateBtnDiv {
	width: 13.5rem;
	height: 32px;
	border: 1px solid var(--label-red-dark);
	border-radius: 3px;
	background-color: var(--label-red-dark);
	display: flex;
	justify-content: start;
}
.StateBtn {
	width: 10rem;
	height: 30px;
	border: 1px solid #808080;
	border-radius: 3px;
	font-size: x-small;
	font-weight: bold;
}

.StateLabel {
	width: 2.5rem;
	height: 25px;
	border: 1px solid #cfd5db;
	border-radius: 3px;
	background-color: #f1f3f5;
	margin-left: 10px;
	margin-top: 3px;
	padding-left: 3px;
	padding-top: 2px;
	font-size: small;
	font-weight: bold;
	text-align: center;
}

.ActiveStateLabel {
	width: 2.5rem;
	height: 25px;
	border: 1px solid #cfd5db;
	border-radius: 3px;
	background-color: #cccfd1;
	margin-left: 10px;
	margin-top: 3px;
	padding-left: 3px;
	padding-top: 2px;
	font-size: small;
	font-weight: bold;
	text-align: center;
}

.Panel {
	padding: 10px;
	background-color: #f6f6f6;
	border-top: 1px solid #cfd5db;
}

.BtnPanel {
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
}

@media (max-width: 576px) {
	.BtnPanel {
		justify-content: center;
	}
}
